import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby";
import RecursiveNavbarElement from '../components/mobileRecursiveNavbarElement';
import CollectionNavbarElement from '../components/mobileCollectionNavbarElement';


class QueryGetter extends React.Component{
    render(){
        let data = this.props.data.map((item) => {
            if(item.node.frontmatter.collectionTitle != null){
                let innerItem = item.node.frontmatter.collectionList;
                return innerItem.map(item => {
                    return(
                        <div>
                            <CollectionNavbarElement title={item} />
                            <RecursiveNavbarElement belongs={item} />
                        </div>
                    );
                })
            }
        });

        return(<div>
            {data}
        </div>)
    }
}


class RecursiveNavbar extends React.Component{
    render(){
        return(
            <StaticQuery
    query={graphql`
    query {
    allMarkdownRemark{
        edges{
            node {
                frontmatter {
                        path
                        title
                        subtitle
                        belongsTo
                        image
                        descriptionTitle
                        description
                        collectionTitle
                        collectionList
                        contains
                        isPartOf
                        subCollectionTitle
                }
            }
        }
    }
      }
    `}
    render={data => <QueryGetter data={data.allMarkdownRemark.edges} />}
  />
        );
    }
}
export default RecursiveNavbar