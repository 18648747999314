import React from 'react'
import { Link } from "gatsby";
import { Columns, Container } from 'react-bulma-components';
import Dropdown from '../components/dropdown';
import Breaker from '../../static/breaker.png';
import MobileNavbar from '../components/mobileNavbar';
import Logo from '../../static/thatLogo.svg';

class AboutUs extends React.Component{
    
    render(){
        if(this.props.hover === 'about'){
            return <Link to='/about' className="navItem mainThing "><li className="thatLink "><p className="textItem smally thatBorder hovering topBar">About Us</p></li></Link>
        }
        else
        return <Link to='/about' className="navItem mainThing "><li className="thatLink "><p className="textItem smally hovering thatBorder">About Us</p></li></Link>
    }
}
class LineDropDown extends React.Component{
    
    render(){
        if(this.props.hover === 'product'){
            return <li className="navItem"><Dropdown hover='product'></Dropdown></li>
        }
        else
        return <li className="navItem"><Dropdown></Dropdown></li>
    }
}
class ContactUs extends React.Component{
    render(){
        if(this.props.hover === 'contact'){
            return  <Link to='/contact' className="navItem mainThing"> <li className="thatLink"><p className="textItem thatBorder smally hovering topBar">Contact Us</p></li></Link>
        }
        else
        return <Link to='/contact' className="navItem mainThing"> <li className="thatLink"><p className="textItem thatBorder hovering smally">Contact Us</p></li></Link>
    }
}
class Career extends React.Component{
    render(){
        if(this.props.hover === 'career'){
            return  <Link to='/career' className="navItem mainThing"><li className="thatLink"><p className="textItem hovering smally topBar">Career</p></li></Link>
        }
        else
        return <Link to='/career' className="navItem mainThing"><li className="thatLink"><p className="textItem hovering smally">Career</p></li></Link>
    }
}

class Navigation extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        return(

            <div className="navBar">
            
            
                        
                
                <ul className="thatNav">
                
                <AboutUs hover={this.props.hover}></AboutUs>
                <img src={Breaker} className="break" />
                   <LineDropDown hover={this.props.hover}></LineDropDown>
                    <img src={Breaker} className="break" />
                    <ContactUs hover={this.props.hover}></ContactUs>
                    <img src={Breaker} className="break" />
                    <Career hover={this.props.hover}></Career>
                </ul>
                
            </div>
        );
    }
}
export default Navigation;