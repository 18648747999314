import React from 'react';
import { Container } from 'react-bulma-components';
import {UnmountClosed} from 'react-collapse';
import Burger from '../../static/burger.png';
import Logo from '../../static/thatLogo.svg';
import MobileRecursive from '../components/mobileRecursiveNavbar';
import { Link } from 'gatsby'
class MobileNavbar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            active: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(){
        this.setState({active: !this.state.active});
    }
    render(){
        
        return(
            <div>
                    <div className="mobileNavbar">
                <div className="mobileNavbarHeader">
                <Link to='/'><img src={Logo} className="mobileNavbarHeaderP" /></Link>
                </div>
                <div  className="mobileNavbarToggle">
                <img onClick={this.handleClick} className="mobileNavbarToggleS" src={Burger} />
                   
                </div>
            </div>
            <UnmountClosed isOpened={this.state.active}>
                <div className="collapsedDiv">
                    <ul>
                        <Link to='/about' className="collapseListElement"><li>About</li></Link>
                        <Link to="/collections" className="collapseListElement"><li>Products</li></Link>
                        
                        <MobileRecursive></MobileRecursive>
                        <Link to='/contact' className="collapseListElement"><li>Contact Us</li></Link>
                        <Link to='/career' className="collapseListElement"><li>Career</li></Link>
                    </ul>
                </div>
            </UnmountClosed>
            
            </div>
            
        );
    }
}
export default MobileNavbar;