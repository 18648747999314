import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby";
import NavbarElement from '../components/mobileNavbarElement';

class QueryGrabber extends React.Component{
    render(){
        let data = this.props.data.map((item) => {
            if(item.node.frontmatter.subCollectionTitle != null && item.node.frontmatter.isPartOf === this.props.belongs){
                let trimmed = item.node.frontmatter.subCollectionTitle.replace(/ /g,'').toLowerCase();
                let link = `/${trimmed}`;
                return(<NavbarElement link={link} title={item.node.frontmatter.subCollectionTitle} />);
            }
        });

        return(<div>
            {data}
        </div>)
    }
}

class RecursiveNavbarElement extends React.Component{
    render(){
        return(
            <StaticQuery
    query={graphql`
    query{
    allMarkdownRemark{
        edges{
            node {
                frontmatter {
            path
            title
            subtitle
            belongsTo
            image
            descriptionTitle
            description
            collectionTitle
            collectionList
            contains
            isPartOf
            subCollectionTitle
                }
            }
        }
    }
      }
    `}
    render={data => <QueryGrabber belongs={this.props.belongs} data={data.allMarkdownRemark.edges} />}
  />
        )
    }
}
export default RecursiveNavbarElement
