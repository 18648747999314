import React from 'react';
import { Link } from 'gatsby';
import RecursiveNavbarElement from '../components/recursiveNavbarElement';
import RecursiveNavbar from '../components/recursiveNavbar';
class ThatDrop extends React.Component{
    render(){
        if(this.props.hover === 'product'){
            return <li className='theFirst thatNotLink mainThing trigger'><p className="textItem thatBorder hovering smally topBar"><Link className="productlink" to="/collections">Products</Link></p></li>
        }
        else {
           return <li className='theFirst thatNotLink mainThing trigger'><p className="textItem thatBorder hovering smally"><Link className="productlink" to="/collections">Products</Link></p></li>
        } 
    }
    }
    
class Dropdown extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(<ul className="dropDownList">
        <ThatDrop hover={this.props.hover}></ThatDrop>
        <div className='hiddenNavSide'>
       
            <RecursiveNavbar></RecursiveNavbar>
            <li className="dropNavItem breather"><Link className="thatLink"><p className="dropSideTextItem"></p></Link></li>
            
        </div>
    </ul>)
    }
}

export default Dropdown
