import React from 'react';
import Logo from '../../static/thatLogo.svg';
import Navigation from '../components/navigation';
import { Hero, Container } from 'react-bulma-components';
import { Link } from 'gatsby';
import Media from 'react-media';
import MobileNavbar from '../components/mobileNavbar';
import Helmet from 'react-helmet'
import Favicon from '../../static/favicon.ico';

class Nav extends React.Component{
    render(){
        return(
            <div>
                <Media
          query="(min-width: 900px)"
          render={() => 
          <div><div className="centering"><Link className="centering" to='/'><img className="thatLogo" src={Logo} /></Link></div><Navigation className="regularNavbar" hover={this.props.hover}></Navigation> </div>
          }
        />
        <Media
          query="(max-width: 900px)"
          render={() => <MobileNavbar className="mobileNav"></MobileNavbar>}
        />
            </div>
        );
    }
}

class Layout extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        let className = 'layoutBody';
        if(this.props.nomargin){
            className = 'layoutBodyNoMargin';
        }
        return(
            <Hero size="fullheight" className="theMain">
            <Helmet>
  <title>{this.props.title}</title>
  
  <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
</Helmet>

            <Hero.Head>
            
      <Nav hover={this.props.hover}></Nav>
        
                    
            </Hero.Head>
                <Hero.Body className={className}>
                {this.props.children}
                </Hero.Body>
            <Hero.Footer className="thatFoot">
            <p>&copy; {(new Date().getFullYear())} Lebanon Poultry | Designed by <a href="mailto:amalsabsaby90@gmail.com?Subject=Hello There!" target="_blank">Amal Sabsabi</a> | Developed by <a href="https://247studios.me/">247studios</a></p>
            </Hero.Footer>
                
            </Hero>
        );
    }
}
export default Layout